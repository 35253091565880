import React from 'react';
import Home from "./Home"
import Topnav from "./Topnav"
import Footer from "./Footer"
import Contact from './Contact'

function App() {

	return ( 
			<>
		<Topnav/>

		<Home/>
		<Contact/>
		<div style={{height: "100px"}}></div>
		<Footer/>
		</>
	)
}
export default App
