import React from 'react';
import { BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './pages/App.js';
import "./Styling.css"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App  />
		</BrowserRouter>
	</React.StrictMode>
)

