import Original from './Original.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

function Services() {
	  return (
			<Container fluid style={{paddingTop: "125px", paddingBottom: "100px"}}className={"d-flex justify-content-center"}>
		  	<Row>
		  <Col>
		  	<Card border="info" bg="dark"style={{ color: "white",width: '25rem'}}>
		  <Card.Header as="h1">$150</Card.Header>
		  <Card.Img variant="top" src={Original}/>
		  <Card.Body>
		  <Card.Title><h1 style={{paddingLeft: "30%"}}>Level 1</h1></Card.Title>

		  <Card.Text className="d-flex justify-content-center" style={{paddingRight: "30px"}}>
		  <ul>
			<li><h4>Maximum 5 pages</h4></li>
			<li><h4>12 days post completion edits Free</h4></li>
			<li><h4>Max 5 photos or vidoes</h4></li>
			<li><h4>No hosting charge</h4></li>
		</ul>
		</Card.Text>
		</Card.Body>
		  	</Card>
		  </Col>
		  <Col>
				<Card border="warning" bg="dark" style={{color: "white",width: '25rem'}}>
		  <Card.Header as="h1">$250</Card.Header>
		  <Card.Img variant="top" src={Original}/>
		  <Card.Body>
		  <Card.Title><h1 style={{paddingLeft: "30%"}}>Level 2</h1></Card.Title>
		   
		  <Card.Text className="d-flex justify-content-center" style={{paddingRight: "30px"}}>

		  <ul>
			<li><h4>Maximum 10 pages</h4></li>
			<li><h4>Email and phone synced up to website forms</h4></li>
			<li><h4>60 days post completion edits FREE</h4></li>
		  	<li><h4> 4 complimentary photos or videos from IStock.com</h4></li>
		  	<li><h4> 7$ per month website hosting </h4></li>
		</ul>
		</Card.Text>
		</Card.Body>
		  	</Card>

		  </Col>
		  <Col>
		  	<Card border="danger" bg="dark" style={{width: '25rem', color: "white"}}>
		  <Card.Header as="h1">$500</Card.Header>
		  <Card.Img variant="top" src={Original}/>
		  <Card.Body>
		  <Card.Title><h1 style={{paddingLeft: "30%"}}>Level 3</h1></Card.Title>
		<Card.Text className="d-flex justify-content-center" style={{paddingRight: "30px"}}>

		  <ul>
			<li><h4>Full Stack Website with Database</h4></li>
			<li><h4>Optional In person meeting any location of your choice</h4></li>
			<li><h4>Unlimited 24/7 phone & email communication</h4></li>
			<li><h4>V.I.P priority</h4></li>
		  	<li><h4>Website hosting charge(More than level 2)</h4></li>
		</ul>
		</Card.Text>
		</Card.Body>
		  	</Card>

		  </Col>
		  	</Row>
		  	</Container>


		    );
}

export default Services;

