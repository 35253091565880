import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'

import Vision from './visionary.png';
import Mavis from './Mavis.png';
import Herbert from './Herbert.png'
import updatedVisionary from './updatedVisionary.png';



function Testimonials() {

	const list = { 
		'Visionary General Contractor': {src: Vision, url: "https://www.visionarygcllc.com/", summary: "Working with Brandon was a game-changer for us. His expertise and professionalism brought our projects to life with unmatched precision. We're grateful for his dedication to excellence.", quote: " - Abraham Reyes"},
		'Event Planning by Mavis Simpson':{src : Mavis, url: "https://calm-dune-0c5ba631e.4.azurestaticapps.net/", summary: "We couldn't have asked for a better partner than Brandon. His creativity and attention to detail made our events truly unforgettable. We highly recommend him for any occasion.", quote: " - Mavis Simpson"},
		'Affordable Landscaping' : {src: Herbert, url: "https://www.affordablelandscapeandmaintenancellc.com/", summary: " Brandon exceeded our expectations with his landscaping skills. He transformed our spaces into beautiful, sustainable landscapes that continue to impress our clients. We appreciate his commitment to quality.", quote: " - Herbert Nashion"},
		'Upgrade for VisionaryGCLLC': {src: updatedVisionary, url: " https://gentle-bay-065cce71e.4.azurestaticapps.net/", summary:  "Collaborating with Brandon was a breath of fresh air. His innovative ideas and flawless execution elevated our projects to new heights. We're grateful for his professionalism and dedication to our success.", quote: " - Abraham Reyes"}
	}
	return (
        
		<Row xs={1} md={2} className="g-4" style={{marginRight:"125px"}}>

{Object.entries(list).map(([name, { src, url, summary, quote }], idx) => (
    <Col key={idx}>
        <Card style={{ marginRight: "100px", width: "45rem", backgroundColor: "#D8FF91"}} className="text-center">
            <Card.Img variant="top" src={src} />
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>
                    {summary}<h3 style={{marginTop: "10px"}}>{quote}</h3>
                </Card.Text>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <Button variant="dark">Go to site</Button>
                </a>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">February 2024</small>
            </Card.Footer>
        </Card>
    </Col>

			))}
		</Row>
	);
}

export default Testimonials;